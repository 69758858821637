import { useState, useEffect } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function InputText({
                     labelTitle,
                     labelStyle,
                     type,
                     containerStyle,
                     defaultValue,
                     placeholder,
                     updateFormValue,
                     updateType,
                   }) {
  const [value, setValue] = useState(defaultValue);
  const [inputType, setInputType] = useState(type || "text");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // Debounce for updating input value
  const updateInputValue = (val) => {
    setValue(val);
    if (updateFormValue) {
      updateFormValue(updateType, val);
    }
  };

  const handleInputChange = (e) => {
    updateInputValue(e.target.value);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  // Handle paste event to ensure all characters are captured
  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");
    updateInputValue(pastedValue);
  };

  return (
    <div className={`form-control w-full ${containerStyle}`}>
      <label className="label">
        <span className={"label-text text-base-content font-semibold " + labelStyle}>
          {labelTitle}
        </span>
      </label>
      <div className="relative">
        <input
          type={inputType}
          value={value}
          placeholder={placeholder || ""}
          onChange={handleInputChange}
          onPaste={handlePaste}
          className="input input-bordered w-full pr-10"
        />
        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            {inputType === "password" ? (
              <EyeIcon className="h-5 w-5 text-normal-1" />
            ) : (
              <EyeSlashIcon className="h-5 w-5 text-normal-1" />
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export default InputText;
