import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'modal',
    initialState: {
        token: localStorage.getItem("token") || "",
    },
    reducers: {
        login: (state, action) => {
            const {token} = action.payload
            state.token = token
        },
        logout: (state, _) => {
            state.token = "";
            localStorage.setItem("token", "")
            localStorage.setItem("user", "");
            window.location.reload();
        },
        logoutWithoutRederict: (state, _) => {
            state.token = "";
            localStorage.setItem("token", "")
            localStorage.setItem("user", "");
        },
        setClient: (state, action) => {
            state.client = action.payload.clientId;
        },

    }
})

export const { login, logout, setClient, logoutWithoutRederict } = authSlice.actions

export default authSlice.reducer
