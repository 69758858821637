import axios from "axios"
import { configurationLoad } from "../helpers/InfoApiModule";
import { Exception } from "sass";
import { getCityFromPath } from "./auth";
// const dotenv = require("dotenv");
// dotenv.config();

const initializeApp = () => {

    // Setting base URL for all API request via axios
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    axios.interceptors.response.use(
      response => {
          return response;
      },
      error => {
          if (error.response && error.response.status === 401 && !window.location.href.includes("?login")) {
              localStorage.removeItem("token")
              localStorage.removeItem("user");
              const cityName = getCityFromPath();
              window.location.href = cityName ? '/' + cityName + "/app/dashboard?login" : '/app/dashboard?login';
              return;
          }
          if (!window.location.href.includes("/error") && !error.response.status.toString().startsWith("4")) {
              window.location.href = "/error?errorText=" + encodeURIComponent(error);
          }
          return Promise.reject(error);      }
    );

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code

    } else {
        // Prod build code



        // Removing console.log from prod
        console.log = () => {};


        // init analytics here
    }

    //fetch("https://nx49405.your-storageshare.de/s/nxwetmcRfk6THyq/download/customstyle.css").then((response) => {

    //})

    //const link = document.createElement("link");
    //link.href = "https://nx49405.your-storageshare.de/s/nxwetmcRfk6THyq/download/customstyle.css"
    //link.rel = "stylesheet";
    //link.type = "text/css"

    //document.head.appendChild(link)

}

export default initializeApp
