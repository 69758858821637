import { useState } from 'react';
import ErrorText from '../../components/Typography/ErrorText';
import InputText from '../../components/Input/InputText';
import { useDispatch, useSelector } from 'react-redux';
import { activateUser, auth } from "../../helpers/AuthApiModule";
import { login } from '../common/authSlice';
import { SCSS_PREFIX } from "../../helpers/Variables";
import { useAtom } from "jotai";

function ActivateUser() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.header.theme);
  const INITIAL_LOGIN_OBJ = {
    password: '',
    confirmPassword: '',
    puk: '',
    emailId: '',
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 10) errors.push('mindestens 10 Zeichen lang sein');
    if (!/[A-Z]/.test(password)) errors.push('einen Großbuchstaben enthalten');
    if (!/[a-z]/.test(password)) errors.push('einen Kleinbuchstaben enthalten');
    if (!/\d/.test(password)) errors.push('eine Zahl enthalten');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('ein Sonderzeichen enthalten');
    return errors;
  };

  const submitForm = async (e) => {

    console.log(loginObj)

    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (loginObj.emailId.trim() === '') return setErrorMessage('Benutzername ist erforderlich!');
    if (loginObj.password.trim() === '') return setErrorMessage('Passwort ist erforderlich!');

    const passwordErrors = validatePassword(loginObj.password);
    if (passwordErrors.length > 0) return setErrorMessage(`Das Passwort muss ${passwordErrors.join(', ')}!`);
    if (loginObj.password !== loginObj.confirmPassword) return setErrorMessage('Passwörter stimmen nicht überein!');

    setLoading(true);
    try {
      await activateUser(loginObj.emailId, loginObj.password, loginObj.puk);
      setSuccessMessage('Benutzer erfolgreich aktiviert!');
    } catch (error) {
      setErrorMessage('Die PUK ist nicht korrekt.');
    }
    setLoading(false);
  };

  const updateFormValue = (updateType, value) => {
    console.log("Update Type " + updateType + value)
    setErrorMessage('');
    setSuccessMessage('');
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  return (
    <div className="flex items-center">
      <div
        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
        style={{ display: loading ? 'flex' : 'none' }}
      >
        <div className="bg-white p-8 rounded-md">
          <p>Loading...</p>
        </div>
      </div>
      <div className="card mx-auto w-full login-card relative">
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"
          style={{
            background: `url('/login${theme == 'dark' ? '-dark' : ''}.png')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="py-24 px-10 relative z-10">
          <h1 className="text-4xl mb-2 text-center">Benutzer aktivieren</h1>
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="mb-4">
              <InputText
                type="emailId"
                defaultValue={loginObj.emailId}
                updateType="emailId"
                containerStyle={SCSS_PREFIX + "login_input_username"}
                labelTitle="Benutzername"
                updateFormValue={updateFormValue}
              />
              <InputText
                defaultValue={loginObj.password}
                type="password"
                updateType="password"
                containerStyle={SCSS_PREFIX + "login_input_password"}
                labelTitle="Passwort"
                updateFormValue={updateFormValue}
              />
              <InputText
                defaultValue={loginObj.confirmPassword}
                type="password"
                updateType="confirmPassword"
                containerStyle={SCSS_PREFIX + "login_input_password"}
                labelTitle="Passwort wiederholen"
                updateFormValue={updateFormValue}
              />
              <InputText
                defaultValue={loginObj.puk}
                type="puk"
                updateType="puk"
                containerStyle={SCSS_PREFIX + "login_input_username"}
                labelTitle="PUK"
                updateFormValue={updateFormValue}
              />
            </div>
            {errorMessage && <ErrorText styleClass="my-4 text-white">{errorMessage}</ErrorText>}
            {successMessage && <p className="my-4 text-white">{successMessage}</p>}
            <button
              type="submit"
              className={`btn my-4 w-full btn-primary bg-sidebar-1 ${loading ? 'loading' : ''} ${' ' + SCSS_PREFIX + "login_button"}}`}
            >
              Benutzer aktivieren
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ActivateUser;
