import React, { useState, useEffect } from 'react';
import { atom, useAtom } from "jotai";
import { dashboard as loadDashboard } from "./helpers/AuthApiModule";
import { guestdashboard } from "./helpers/InfoApiModule";
import { CustomTheme } from "./models/typescript/CustomTheme";
import {
  DashboardConfiguration,
  getThemeByThemeType,
  GuiConfig, StandardThemeType,
  Theme
} from "./models/typescript/DashboardConfiguration";
import { CustomModal } from "./models/typescript/CustomModal";
import axios from "axios";
import { getCityFromPath } from "./app/auth";
import { logout, logoutWithoutRederict } from "./features/common/authSlice";
import { useDispatch } from "react-redux";
import SuspenseContent from "./containers/SuspenseContent";

export const themeAtom = atom<CustomTheme | null>(null);
export const dashboardAtom = atom<DashboardConfiguration | null>(null);
export const filterAtom = atom("");
export const navbarAtom = atom<any[]>([]);
export const modalAtom = atom<CustomModal | null>(null);
export const webConfigAtom = atom<WebConfig | null>(null);

// Temporäres Atom für Dev Phase
export const selectedThemeAtom = atom<StandardThemeType | null>(null);

const params = new URLSearchParams(window.location.search);

interface WebConfig {
  serviceUrl: string;
  org_key: string;
  hash_router: boolean;
}

export const refreshDashboard = async (setDashboard: React.Dispatch<React.SetStateAction<DashboardConfiguration | null>>) => {
  // Falls eine ClientId gesetzt wurde, dann wird diese übernommen
  const clientId = params.get('clientId');
  if (clientId) {
    localStorage.setItem("client", clientId);
  } else {
    const token = localStorage.getItem("token");

    const tokenRange = localStorage.getItem("token_range");
    const cityFromPath = getCityFromPath() === null ? "null" : getCityFromPath();

    let didUserCityChange = false;

    if (tokenRange !== null) {
      // Wenn tokenRange nicht "null" als String ist und sich von cityFromPath unterscheidet
      if (tokenRange !== cityFromPath) {
        didUserCityChange = true;
      }
    }


    const getDashboard = didUserCityChange ? guestdashboard : token && token.toString().length > 0 ? loadDashboard : guestdashboard;
    if(didUserCityChange) {
      localStorage.removeItem("token");
      localStorage.removeItem("token_range")
      localStorage.removeItem("client")
      window.location.reload();
      return;
    }
    // @ts-ignore
    localStorage.setItem('token_range', getCityFromPath())

    const storedDashboard = await getDashboard(clientId);

    // Prüfen, ob der localStorage-Wert leer ist
    if (localStorage.getItem("client") === null || localStorage.getItem("client") === undefined) {
      // Wenn startupclientid existiert, diesen Wert übernehmen, sonst 0 setzen
      if (storedDashboard.startupclientid !== undefined && storedDashboard.startupclientid !== 0) {
        localStorage.setItem("client", storedDashboard.startupclientid.toString());
        window.location.reload();
      } else {
        localStorage.setItem("client", "0");
      }
    }
  }

  // Andernfalls wird defaultmäßig 0 gesetzt, wenn zuvor nichts anderes ausgewählt wurde.
  if (localStorage.getItem("client") === null || localStorage.getItem("client") === undefined)
    localStorage.setItem("client", "0");

  /**
   * Laden der Dashboard Konfiguration
   */
  const token = localStorage.getItem("token");
  const client = localStorage.getItem("client");
  const getDashboard = token && token.toString().length > 0 ? loadDashboard : guestdashboard;
  const res = await getDashboard(client);

  setDashboard({
    ...res,
    guiconfig: ({
      ...JSON.parse(atob(res.guiconfigcontent))
    }),
    loggedIn: !!token,
  });
};

interface DataLoadingProps {
  children: any;
}

const DataLoading = ({ children }: DataLoadingProps) => {
  const [color, setColors] = useAtom(themeAtom);
  const [dashboard, setDashboard] = useAtom(dashboardAtom);
  const [selectedTheme] = useAtom(selectedThemeAtom);
  const [, setWebConfig] = useAtom(webConfigAtom);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [orgKey, setOrgKey] = useState<string | null>(null)

  useEffect(() => {
    const fetchOrgKeyAndRefreshDashboard = async () => {
      const response = await fetch(`${process.env.PUBLIC_URL}/webconfig.json`);
        const data: WebConfig = await response.json();
        const orgKey = data.org_key;
        const serviceUrl = data.serviceUrl;
      setOrgKey(orgKey)
      axios.defaults.baseURL = serviceUrl;
      axios.defaults.headers.common['x-orgkey'] = orgKey;
        setWebConfig(data);

        await refreshDashboard(setDashboard);

    };

    fetchOrgKeyAndRefreshDashboard();
    setLoading(false);
  }, []);

  useEffect(() => {
    if(orgKey === null) return;
    axios.defaults.headers.common['x-orgkey'] = orgKey;
    setLoading3(false)
  }, [orgKey]);

  useEffect(() => {
    if (dashboard?.guiconfig && dashboard.guiconfig.theme) {
      const guiConfig: GuiConfig = dashboard.guiconfig;

      // Setzen der Page Title -> Config Wert wird ausgewertet
      document.title = guiConfig.pageTitle || "Dashboard";

      // Setzen des dynamischen FavIcons
      const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link') as HTMLLinkElement;

      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = guiConfig.favIcon;

      document.getElementsByTagName('head')[0].appendChild(link);

      if(guiConfig.standardcolor !== null && guiConfig.standardcolor !== undefined) {

        const theme: Theme = getThemeByThemeType(guiConfig.standardcolor);

        setColors({
          primaryColor: theme.primaryColor,
          primaryColor_lighter: theme.primaryColor,
          dark_primaryColor: theme.dark_primaryColor,
          dark_primaryColor_darker: theme.dark_primaryColor_darker,
          selected_primaryColor: theme.dark_primaryColor,
          selected_primaryColor_2: theme.dark_primaryColor_darker
        });
        document.documentElement.style.setProperty('--remote_theme_primary', theme.dark_primaryColor);
        document.documentElement.style.setProperty('--remote_theme_primary2', theme.dark_primaryColor_darker);
        return;
      }

      if (localStorage.getItem("theme") === null || localStorage.getItem("theme") === undefined) {
        setColors({
          primaryColor: guiConfig.theme.primaryColor,
          primaryColor_lighter: guiConfig.theme.primaryColor,
          dark_primaryColor: guiConfig.theme.dark_primaryColor,
          dark_primaryColor_darker: guiConfig.theme.dark_primaryColor_darker,
          selected_primaryColor: guiConfig.theme.dark_primaryColor,
          selected_primaryColor_2: guiConfig.theme.dark_primaryColor_darker
        });
        document.documentElement.style.setProperty('--remote_theme_primary', guiConfig.theme.dark_primaryColor);
        document.documentElement.style.setProperty('--remote_theme_primary2', guiConfig.theme.dark_primaryColor_darker);
      }

      setColors({
        primaryColor: guiConfig.theme.primaryColor,
        primaryColor_lighter: guiConfig.theme.primaryColor,
        dark_primaryColor: guiConfig.theme.dark_primaryColor,
        dark_primaryColor_darker: guiConfig.theme.dark_primaryColor_darker,
        selected_primaryColor: localStorage.getItem("theme") === "light" ? guiConfig.theme.primaryColor : guiConfig.theme.dark_primaryColor,
        selected_primaryColor_2: localStorage.getItem("theme") === "light" ? guiConfig.theme.primaryColor_lighter : guiConfig.theme.dark_primaryColor_darker
      });

      document.documentElement.style.setProperty('--remote_theme_primary', localStorage.getItem("theme") === "light" ? guiConfig.theme.primaryColor : guiConfig.theme.dark_primaryColor);
      document.documentElement.style.setProperty('--remote_theme_primary2', localStorage.getItem("theme") === "light" ? guiConfig.theme.primaryColor_lighter : guiConfig.theme.dark_primaryColor_darker);
    }
    setLoading2(false);
  }, [dashboard]);

  if (loading || loading2 || loading3) {
    return <SuspenseContent />; // Zeigt einen Ladeindikator an, solange die Konfiguration geladen wird
  }

  return children; // Rendert die Kinderkomponenten, sobald die Konfiguration geladen ist
};



export default DataLoading;
